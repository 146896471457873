module.exports = {
  model: [
    {
      name: "Bounce Case MagSafe Compatible",
      color: [
        {
          name: "https://www.casetify.com/img/template/base_iphone-14-pro-max_16004763_white.png",
          img: "Black",
          css: " bg-gray-200",
        },
        {
          name: "https://www.casetify.com/img/template/base_iphone-14-pro-max_16004763_white.png",
          img: "Triple Black",
          css: "",
        },
        {
          name: "https://www.casetify.com/img/template/base_iphone-14-pro-max_16005227_white.png",
          img: "Purple",
          css: "",
        },
        {
          name: "https://www.casetify.com/img/template/base_iphone-14-pro-max_16005235_white.png",
          img: "Pink",
          css: "",
        },
      ],
    },
    {
      name: "Ultra Impact Case MagSafe Compatible",
      color: [
        {
          name: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK043ZM_A_1.png?v=1636113716",
          img: "Blue",
          css: " bg-gray-200",
          img_bg:
            "https://cdn.discordapp.com/attachments/1080058777971212370/1126755719136428042/Iphone_14_01.png",
          textCss: "text-blue-500",
        },
        {
          name: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK053ZM_A_1.png?v=1636113716",
          img: "Green",
          css: "",
          img_bg:
            "https://cdn.discordapp.com/attachments/1080058777971212370/1126755719555846214/Iphone_14_02.png",
          textCss: "text-green-400",
        },
        {
          name: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK083ZM_A_1.png?v=1636113716",
          img: "purple",
          css: "",
          img_bg:
            "https://media.discordapp.net/attachments/1080058777971212370/1126755719811694672/Iphone_14_03.png?width=576&height=1042",
          textCss: "text-purple-700",
        },
        {
          name: "https://media.discordapp.net/attachments/1080058777971212370/1125326031079882852/Iphone_Case_01.png?width=1042&height=1042",
          img: "Black",
          css: "",
          img_bg:
            "https://media.discordapp.net/attachments/1080058777971212370/1126755720113700984/Iphone_14_04.png?width=576&height=1042",
          textCss: "text-gray-900",
        },
      ],
    },
    {
      name: "Impact Case MagSafe Compatible",
      color: [
        {
          name: "https://www.casetify.com/img/template/base_iphone-14-pro-max_16004763_white.png",
          img: "Black",
          css: "bg-gray-200",
        },
        {
          name: "https://www.casetify.com/img/template/base_iphone-14-pro-max_16004763_white.png",
          img: "Triple Black",
          css: "",
        },
        {
          name: "https://www.casetify.com/img/template/base_iphone-14-pro-max_16005227_white.png",
          img: "Purple",
          css: "",
        },
        {
          name: "https://www.casetify.com/img/template/base_iphone-14-pro-max_16005235_white.png",
          img: "Pink",
          css: "",
        },
      ],
    },
    {
      name: "Clear Case MagSafe Compatible",
      color: [
        {
          name: "https://www.casetify.com/img/template/base_iphone-14-pro-max_16004763_white.png",
          img: "Black",
          css: "bg-gray-200",
        },
        {
          name: "https://www.casetify.com/img/template/base_iphone-14-pro-max_16004763_white.png",
          img: "Triple Black",
          css: "",
        },
        {
          name: "https://www.casetify.com/img/template/base_iphone-14-pro-max_16005227_white.png",
          img: "Purple",
          css: "",
        },
        {
          name: "https://www.casetify.com/img/template/base_iphone-14-pro-max_16005235_white.png",
          img: "Pink",
          css: "",
        },
      ],
    },
  ],
};
