module.exports = {
  iPhone_List: [
    {
      name: "iPhone 14 Pro",
      select: false,
    },
    {
      name: "iPhone 14 Pro Max",
      select: false,
    },
    // {
    //   name: "iPhone 14 Plus",
    //   select: false,
    // },
    // {
    //   name: "iPhone 14",
    //   select: false,
    // },
  ],
  AirTag_List: [
    {
      name: "AirTag",
      select: false,
    },
  ],
  iPad_List: [
    {
      name: "iPad 10.9-inch (10th gen)",
      select: false,
    },

    {
      name: "iPad Air (4th/5th gen)",
      select: false,
    },
    {
      name: "iPad mini (6th gen)",
      select: false,
    },

    {
      name: "iPad Pro 12.9-inch (5th/6th gen)",
      select: false,
    },
    {
      name: "iPad Pro 11-inch (3rd/4th gen)",
      select: false,
    },
    {
      name: "iPad Pro 11-inch (1st gen)",
      select: false,
    },
  ],
  Macbook_List: [
    {
      name: 'MacBook Pro 13" (2016 - 2019)',
      select: false,
    },
    {
      name: 'MacBook Pro 15" (2016 - 2019)',
      select: false,
    },
    {
      name: 'MacBook Pro 16" (2019)',
      select: false,
    },
    {
      name: 'MacBook Air 13.6" (2022)',
      select: false,
    },
    {
      name: 'MacBook Air 13" (2010 - 2017)',
      select: false,
    },
    {
      name: 'MacBook Pro 14" (2021 / 2023)',
      select: false,
    },
    {
      name: 'MacBook Pro 16" (2021 / 2023)',
      select: false,
    },
  ],
};
