module.exports = {
  model: [
    {
      name: "iPhone 14 Pro Max",
      model: [
        // {
        //   img: "https://www.casetify.com/img/cases_image_bounce-case.jpg",
        //   name: "Bounce Case MagSafe Compatible",
        //   css: " ",
        // },
        {
          img: "https://www.casetify.com/img/cases_image_magsafe-compatible-ultra-impact-case.jpg",
          name: "Ultra Impact Case MagSafe Compatible",
          css: "bg-gray-200",
        },
        // {
        //   img: "https://www.casetify.com/img/cases_image_magsafe-compatible-impact-case.jpg",
        //   name: "Impact Case MagSafe Compatible",
        //   css: "",
        // },
        // {
        //   img: "https://www.casetify.com/img/cases_image_clear-case.jpg",
        //   name: "Clear Case MagSafe Compatible",
        //   css: "",
        // },
      ],
    },
    {
      name: "iPhone 14 Pro",
      model: [
        // {
        //   img: "https://www.casetify.com/img/cases_image_bounce-case.jpg",
        //   name: "Bounce Case MagSafe Compatible",
        //   css: " bg-gray-200",
        // },
        {
          img: "https://www.casetify.com/img/cases_image_magsafe-compatible-ultra-impact-case.jpg",
          name: "Ultra Impact Case MagSafe Compatible",
          css: "bg-gray-200",
        },
        // {
        //   img: "https://www.casetify.com/img/cases_image_magsafe-compatible-impact-case.jpg",
        //   name: "Impact Case MagSafe Compatible",
        //   css: "",
        // },
        // {
        //   img: "https://www.casetify.com/img/cases_image_clear-case.jpg",
        //   name: "Clear Case MagSafe Compatible",
        //   css: "",
        // },
      ],
    },
    {
      name: "iPhone 14 Plus",
      model: [
        {
          img: "https://www.casetify.com/img/cases_image_bounce-case.jpg",
          name: "Bounce Case MagSafe Compatible",
          css: " bg-gray-200",
        },
        {
          img: "https://www.casetify.com/img/cases_image_magsafe-compatible-ultra-impact-case.jpg",
          name: "Ultra Impact Case MagSafe Compatible",
          css: "",
        },
        {
          img: "https://www.casetify.com/img/cases_image_magsafe-compatible-impact-case.jpg",
          name: "Impact Case MagSafe Compatible",
          css: "",
        },
        {
          img: "https://www.casetify.com/img/cases_image_clear-case.jpg",
          name: "Clear Case MagSafe Compatible",
          css: "",
        },
      ],
    },
    {
      name: "iPhone 14",
      model: [
        {
          img: "https://www.casetify.com/img/cases_image_bounce-case.jpg",
          name: "Bounce Case MagSafe Compatible",
          css: " bg-gray-200",
        },
        {
          img: "https://www.casetify.com/img/cases_image_magsafe-compatible-ultra-impact-case.jpg",
          name: "Ultra Impact Case MagSafe Compatible",
          css: "",
        },
        {
          img: "https://www.casetify.com/img/cases_image_magsafe-compatible-impact-case.jpg",
          name: "Impact Case MagSafe Compatible",
          css: "",
        },
        {
          img: "https://www.casetify.com/img/cases_image_clear-case.jpg",
          name: "Clear Case MagSafe Compatible",
          css: " bg-gray-200",
        },
      ],
    },
  ],
};
