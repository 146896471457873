<template>
  <div class="bg-gray-500">
    <div class="z-0 pt-20 pb-20">
      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 p-2"
      >
        <div class="grid grid-cols-1 gap-2 lg:grid-cols-4 md:grid-cols-2 mt-10">
          <div>
            <div class="flex text-gray-900 mt-4">
              <a class="m-2 cursor-pointer" href="" target="_blank">
                <i class="fab fa-twitter" style="font-size: 30px"></i>
              </a>

              <a class="m-2 cursor-pointer" href="" target="_blank">
                <i class="fab fa-reddit" style="font-size: 30px"></i>
              </a>

              <a class="m-2 cursor-pointer" href="" target="_blank">
                <i class="fab fa-youtube" style="font-size: 30px"></i>
              </a>

              <a class="m-2 cursor-pointer" href="" target="_blank">
                <i class="fab fa-instagram" style="font-size: 30px"></i>
              </a>

              <a class="m-2 cursor-pointer" href="" target="_blank">
                <i class="fab fa-facebook" style="font-size: 30px"></i>
              </a>
            </div>

            <div class="text-gray-900 text-sm mt-10">
              © 2023 GenAlgency Foundation. All rights reserved.
            </div>
          </div>

          <div class="flex items-center justify-center">
            <div class="flex-none">
              <h3 class="text-xl pt-2 text-gray-200">Customization</h3>
            </div>
          </div>

          <div class="flex items-center justify-center">
            <div class="flex-none">
              <h3 class="text-xl pt-2 text-gray-200">Products</h3>
            </div>
          </div>

          <div class="flex items-center justify-center">
            <div class="flex-none">
              <h3 class="text-xl pt-2 text-gray-200">New & Featured</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
